import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";

const axios = require("axios").default;

const MyMassiveLinks = () => {

    const search = useLocation().search;

    // URL PARAMS
    const typeDocument = new URLSearchParams(search).get('typeDocument')
    const token = new URLSearchParams(search).get('token')
    const quableAppType = new URLSearchParams(search).get('quableAppType')
    const pimInstance = new URLSearchParams(search).get('quableInstance')
    let listIds = new URLSearchParams(search).get('ids')
    listIds = listIds.split(',');
    const pimHost = `https://${pimInstance}.quable.com/api`

    // set OPTIONS
    const [allItems, setAllItems] = useState([]);
    const [selectOptions, setSelectOption] = useState([])
    const [selectedOption, setSelectedOption] = useState(allItems[0]);
    const [inputValue, setInputValue] = useState('');
    const [loader, setLoader] = useState(false)
    const [resultMessage, setResultMessage] = useState("")
    const [isLoading, setIsLoading] = useState(false);
    const [listAttr, setListAttr] = useState([]);
    const [forceEmpty, setForceEmpty] = useState(false);


    // UPDATE SELECTIONS
    const [selectedItems, setSelectedItems] = useState([]);

    const fillSelectTypeDocumentParent = async () => {
        let req = ""
        if (quableAppType === "mass_link_parent_document") {
            req = "target.documentType.id="
        }
        else if (quableAppType === "mass_link_child_document") {
            req = "origin.documentType.id="
        }
        else if (quableAppType === "mass_link_media") {
            req = "origin.documentType.id="
        }
        else
            return []

        const propositions = await axios({
            method: "GET",
            url: `${pimHost}/link-types/?${req}${typeDocument}`,
            headers: {
                accept: 'application/ld+json',
                Authorization: `Bearer ${token}`
            },
        })
        console.log(`${pimHost}/link-types/?${req}${typeDocument}`)

        if (propositions.status !== 200) {
            return console.log("An Error occured gettin Ltype of documents")
        }

        if (quableAppType === "mass_link_media")
            return propositions.data['hydra:member'].filter(obj => obj.target && obj.target.objectType === "asset");
        else
            return propositions.data['hydra:member'].filter(obj => obj.target && obj.target.objectType === "document");
    }


    const extractAttributesFromLinkConfig = (data) => {
        const listAttr = []
        if ( data?.children && data?.children.length > 0) {
            for (const child of data?.children) {
                const possibleAttr = extractAttributesFromLinkConfig(child)
                if (possibleAttr != null) {
                    if (!Array.isArray(possibleAttr)){
                        console.log("attr", possibleAttr)
                        listAttr.push(possibleAttr)
                    } else {
                        listAttr.push(...possibleAttr)
                    }
                    
                }
            }
            return listAttr
        } else if (data?.treeLevel){
            return null
        } else {
            return data
        }
    }

    const getAttributeLink = async (linkId) => {
        const linkConfig = await axios({
            method: "GET",
            url: `${pimHost}/document-types/${linkId}/attributes`,
            headers: {
                accept: 'application/ld+json',
                Authorization: `Bearer ${token}`
            },
        })
        console.log(`${pimHost}/document-types/${linkId}/attributes`)

        if (linkConfig.status !== 200) {
            console.log("An Error occured getting link attributes")
            return []
            
        }


        if (linkConfig.data['hydra:member'].length === 0) {
            return  []
        } else {
            console.log(linkConfig.data['hydra:member'])
            let listAttr = []
            for (const child of linkConfig.data['hydra:member']) {
                console.log("child",child)
                const attr = extractAttributesFromLinkConfig(child)
                if (attr !== null) {
                    if (! Array.isArray(attr)){
                        listAttr.push(attr)
                    } else {
                        listAttr.push(...attr)
                    }
                }
                
            }
            return listAttr

        }


    }

    const getListOptionDropdown = async (attrId) => {
        const attrConfig = await axios({
            method: "GET",
            url: `${pimHost}/predefined-values?attribute.id=${attrId}`,
            headers: {
                accept: 'application/ld+json',
                Authorization: `Bearer ${token}`
            },
        })
        if (attrConfig.status !== 200) {
            console.log("An Error occured getting link attributes")
            return []
            
        }

        if (attrConfig.data['hydra:member'].length === 0) {
            return  []
        } else {
            console.log(attrConfig.data['hydra:member'])
            let listAttrValue = []
            for (const child of attrConfig.data['hydra:member']) {
                console.log("child",child)
                listAttrValue.push(
                    {
                        "name":child.name["fr_FR"],
                        "id":child.id.replace(`attribute=${attrId};code=`,"")
                    }
                )
                
            }
            return listAttrValue

        }
    }

    const retrieveSuggestions = async (input) => {
        const typeOfLink = selectOptions.find(item => item.id === selectedOption)

        let documentTypeId = ""
        if (quableAppType === "mass_link_media") {
            const propositions = await axios({
                method: "GET",
                url: `${pimHost}/assets/?search=${input}`,
                headers: {
                    accept: 'application/hal+json',
                    Authorization: `Bearer ${token}`
                }
            })
            if (propositions.status === 200) {

                if ((propositions.data?.totalItems !== 0) && propositions.data['_embedded']['item'].length > 0) {
                    const results = propositions.data['_embedded']['item'].map(data => {
                        return {
                            label: data.name,
                            id: data.id,
                            medias: data.thumbnailUrl || null
                        };
                    });
                    return results
                }
                return []
            }
            else {
                console.log("Error getting Suggestions")
            }
            return []
        }
        else if (quableAppType === "mass_link_child_document") {
            documentTypeId = typeOfLink.target.documentType.id
        } else {
            documentTypeId = typeOfLink.origin.documentType.id
        }
            

        const propositions = await axios({
            method: "GET",
            url: `${pimHost}/documents/?documentType.id=${documentTypeId}&search=${input}`,
            headers: {
                accept: 'application/ld+json',
                Authorization: `Bearer ${token}`
            }
        })
        if (propositions.status === 200) {
            if (propositions.data['hydra:member'].length > 0) {
                const results = propositions.data['hydra:member'].filter(data => data.active === true).map(data => {
                    const attributeName = `${data.documentType.id}_name`;
                    let labelShow = `${data.id}`
                    if (data.attributes[attributeName]) {
                        labelShow = `${data.id} - ` + data.attributes[attributeName]["fr_FR"]
                    }
                    return {
                        label: labelShow,
                        id: data.id,
                        medias: data.mainAssetThumbnailUrl || null
                    };
                });
                return results
            }
            return []
        }
        else {
            console.log("Error getting Suggestions")
        }
        return []

    }

    // HANDLERS
    const handleSelectionClick = (item) => {
        const isItemSelected = selectedItems.some((selectedItem) => selectedItem.id === item.id);

        if (isItemSelected) {
            setSelectedItems((prevSelectedItems) =>
                prevSelectedItems.filter((selectedItem) => selectedItem.id !== item.id)
            );
        } else {
            setSelectedItems((prevSelectedItems) => [...prevSelectedItems, item]);
        }
    };
    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
        getAttributeLink(event.target.value).then((listAttr) => {

            for (let index = 0; index < listAttr.length; index++) {
                if (listAttr[index].type === "simple_select" || listAttr[index].type === "multi_select" ) {
                    getListOptionDropdown(listAttr[index].id).then(
                        (listOptions) => {
                            listAttr[index]["options"] = listOptions
                        }
                    )
                    
                }  else {
                    listAttr[index]["options"] = []
                }
                
            }

            setListAttr(listAttr)
        })
        setResultMessage("")
        setSelectedItems([])
        setAllItems([])
        setInputValue("")
        setForceEmpty(false)
    };

    const addContent =(valueEvent, attr, itemSelect) => {
        selectedItems.map((item) => {
            if(item.id === itemSelect.id){
                if (! item?.linkAttr) {
                    item["linkAttr"] = {}
                }
                if (attr.type === "multiline_text" || attr.type === "localized_text" || attr.type === "html_text") {
                    item["linkAttr"][attr.id] = {"fr_FR":valueEvent}
                } else if(attr.type === "simple_select"){
                    item["linkAttr"][attr.id] = [{"id":valueEvent}]
                } else if(attr.type === "multi_select"){
                    var value = [];
                    for (var i = 0, l = valueEvent.length; i < l; i++) {
                        if (valueEvent[i].selected) {
                            value.push({"id":valueEvent[i].value});
                        }
                    }
                    item["linkAttr"][attr.id] = value
                } else {
                    item["linkAttr"][attr.id] = valueEvent
                }
                

            }

        })
        console.log(selectedItems)
    }

    // HOOKS \\

    //ONMOUNT
    useEffect(() => {
        fillSelectTypeDocumentParent(quableAppType).then((response) => {
            setSelectOption(response)
            setSelectedOption(response[0].id)
            getAttributeLink(response[0].id).then((listAttr) => {
                for (let index = 0; index < listAttr.length; index++) {
                    if (listAttr[index].type === "simple_select" || listAttr[index].type === "multi_select" ) {
                        getListOptionDropdown(listAttr[index].id).then(
                            (listOptions) => {
                                listAttr[index]["options"] = listOptions
                            }
                        )
                        
                    }  else {
                        listAttr[index]["options"] = []
                    }
                    
                }

                setListAttr(listAttr)
            })
        })

    }, [])

    // AUTOCOMPLETE FROM 3 CHARACTERS IN
    useEffect(() => {
        if (inputValue.length >= 3) {
            setLoader(true)
            retrieveSuggestions(inputValue).then((suggestions) => {
                setAllItems(suggestions)
                setLoader(false)
            })
        }
    }, [inputValue])

    // Enregistrer


    const checkAlreadLinkyExist = async (body) => {

        try {
            const exist = await axios.get(`${pimHost}/links?origin.id=${body.origin.id}&target.id=${body.target.id}&linkType.id=${body.linkType.id}`, {
                headers: {
                    Accept: 'application/hal+json',
                    Authorization: `Bearer ${token}`
                }
            })
            console.log("mon exist ::::::",exist)
            if (exist.data && exist.data.totalItems && exist.data.totalItems > 0)
                return exist.data._embedded.item[0]
            else
                return false
        } catch (error) {
            console.log(error.message)
            console.log(error.request)
            return false
            //throw new Error(`Error: ${error.request}`);
        }
    }

    const linkingDocuments = async (body,quableAppType) => {

        try {
            const linked = await axios.post(`${pimHost}/links`, body, {
                headers: {
                    'Content-Type': 'application/ld+json',
                    Accept: 'application/hal+json',
                    Authorization: `Bearer ${token}`
                }
            })

            return { success: true, inputData: body }
        } catch (error) {
            console.error(error.message)
            console.error(error.request)
            try {
                const alreadyExist = await checkAlreadLinkyExist(body)

                if (alreadyExist === false) {
                    return { success: alreadyExist, inputData: body }
                }
                if (quableAppType === "mass_link_media") {
                    return { success: true, inputData: body }
                } else {
                    try {
                        console.log(alreadyExist, body)
                        const linked = await axios.put(`${pimHost}/links/${alreadyExist.id}`, body, {
                            headers: {
                                'Content-Type': 'application/ld+json',
                                Accept: 'application/hal+json',
                                Authorization: `Bearer ${token}`
                            }
                        }) 
                        return { success: true, inputData: body }
                    } catch (error) {
                        console.error(error)
                        return { success: false, inputData: body }
                    }
                    
                }

                
            } catch (error) {
                console.error(error)
                return { success: false, inputData: body }
            }
        }
    }


    const handleSaveClick = async () => {
        //        console.log("Liste des ids de documents récupérés :", listIds, "option selectionnée :::::::", selectedOption, "\nliste des ids des elements sélectionnés :::::: ", selectedItems);

        const bodies = [];

        if (selectedItems.length === 0)
            return

        if (quableAppType === "mass_link_parent_document") {

            listIds.forEach(origin => {
                selectedItems.forEach(targetObject => {
                    const body = {
                        origin: { id: targetObject.id },
                        target: { id: origin },
                        linkType: { id: selectedOption }
                    };
                    if (targetObject?.linkAttr) {
                        body["attributes"] = {}
                        for (const key in targetObject?.linkAttr) {
                            // TODO gestion du typage
                            body["attributes"][key] = targetObject.linkAttr[key]
                        }

                    }

                    console.log("force empty",forceEmpty)
                    if (forceEmpty === true || forceEmpty === "on"){
                        if (!body.linkType.attributes){
                            body["attributes"] = {}
                        }
                        console.log("attr",listAttr)
                        if(listAttr) {
                            for (let index = 0; index < listAttr.length; index++) {
                                if (!body["attributes"][listAttr[index].id]) {
                                    if (listAttr[index].type === "simple_select" || listAttr[index].type === "multi_select") {
                                        body["attributes"][listAttr[index].id] = {}
                                    }else if(listAttr[index].type === "multiline_text" || listAttr[index].type === "localized_text" || listAttr[index].type === "html_text"){
                                        body["attributes"][listAttr[index].id] = {"fr_FR":""}
                                    } else if (listAttr[index].type === "integer" || listAttr[index].type === "decimal" ) {
                                        body["attributes"][listAttr[index].id] = null                                        
                                    } else {
                                        body["attributes"][listAttr[index].id] = ""
                                    }
                                }
                                
                                
                            }
                        
                        }
                        console.log("bodyUpdate",body)
                        
                    }

                    const isDuplicate = bodies.some(existingBody =>
                        existingBody.origin === body.origin && existingBody.target === body.target
                    );

                    if (!isDuplicate) {
                        bodies.push(body);
                    }
                });
            });
            setResultMessage("Association en cours")


            bodies.sort( (a,b) => {
                return a.origin.id.localeCompare(b.origin.id)
            })


            let messageOutput = ""
            for (let index = 0; index < bodies.length; index++) {
                try {
                    const result = await linkingDocuments(bodies[index],quableAppType)
                    console.log("mon result ::::::", result)
                    if (result.success === true) {
                        messageOutput += `${result.inputData.origin.id} \u2194 ${result.inputData.target.id} OK \n`
                    } else {
                        messageOutput += `${result.inputData.origin.id} \u2194 ${result.inputData.target.id} NOK \n`
                    }
                } catch (error) {
                    messageOutput += `${bodies[index].origin.id} \u2194 ${bodies[index].target.id} NOK \n`
                }
                
            }
            setResultMessage(messageOutput)

        }
        else if (quableAppType === "mass_link_media" || quableAppType === "mass_link_child_document") {

            listIds.forEach(origin => {
                selectedItems.forEach(targetObject => {
                    const body = {
                        origin: { id: origin },
                        target: { id: targetObject.id },
                        linkType: { id: selectedOption }
                    };
                    if (targetObject?.linkAttr) {
                        body["attributes"] = {}
                        for (const key in targetObject?.linkAttr) {
                            body["attributes"][key] = targetObject.linkAttr[key]
                        }

                    }
                    console.log("force empty",forceEmpty)
                    if (forceEmpty === true || forceEmpty === "on"){
                        if (!body.linkType.attributes){
                            body["attributes"] = {}
                        }
                        console.log("attr",listAttr)
                        if(listAttr) {
                            for (let index = 0; index < listAttr.length; index++) {
                                if (!body["attributes"][listAttr[index].id]) {
                                    if (listAttr[index].type === "simple_select" || listAttr[index].type === "multi_select") {
                                        body["attributes"][listAttr[index].id] = {}
                                    }else if(listAttr[index].type === "multiline_text" || listAttr[index].type === "localized_text" || listAttr[index].type === "html_text"){
                                        body["attributes"][listAttr[index].id] = {"fr_FR":""}
                                    } else if (listAttr[index].type === "integer" || listAttr[index].type === "decimal" ) {
                                        body["attributes"][listAttr[index].id] = null                                        
                                    }  else {
                                        body["attributes"][listAttr[index].id] = ""
                                    }
                                }
                                
                                
                            }
                        
                        }
                        console.log("bodyUpdate",body)
                        
                    }
                    

                    const isDuplicate = bodies.some(existingBody =>
                        existingBody.origin === body.origin && existingBody.target === body.target
                    );

                    if (!isDuplicate) {
                        bodies.push(body);
                    }
                });
            });
            setResultMessage("Association en cours")

            let messageOutput = ""
            for (let index = 0; index < bodies.length; index++) {
                try {
                    const result = await linkingDocuments(bodies[index],quableAppType)
                    console.log("mon result ::::::", result)
                    if (result.success === true) {
                        if (quableAppType === "mass_link_media") {
                            const targetMedia = selectedItems.filter(item => item.id == result.inputData.target.id)
                            messageOutput += `${result.inputData.origin.id} \u2194 ${targetMedia[0].label} OK \n`
                        } else {
                            messageOutput += `${result.inputData.origin.id} \u2194 ${result.inputData.target.id} OK \n`
                        }
                    } else {
                        if (quableAppType === "mass_link_media") {
                            const targetMedia = selectedItems.filter(item => item.id == result.inputData.target.id)
                            messageOutput += `${result.inputData.origin.id} \u2194 ${targetMedia[0].label} NOK \n`
                        } else {
                            messageOutput += `${result.inputData.origin.id} \u2194 ${result.inputData.target.id} NOK \n`
                        }
                        
                    }
                } catch (error) {
                    if (quableAppType === "mass_link_media") {
                        const targetMedia = selectedItems.filter(item => item.id == bodies[index].target.id)
                        messageOutput += `${bodies[index].origin.id} \u2194 ${targetMedia[0].label} NOK \n`
                    } else {
                        messageOutput += `${bodies[index].origin.id} \u2194 ${bodies[index].target.id} NOK \n`
                    }
                    
                }
                
            }
            setResultMessage(messageOutput)


            
        }
    };

    return (
        <div style={{ display: 'flex', height: '100vh', margin: "0px 20px" }}>

            <div style={{ flex: '1', "margin-top": "15px", flexDirection: "column" }}>
                <div style={{ flex: "1" }}>
                    <label style={{ "font-size": "14px", "font-family": "Poppins, Arial, sans-serif", padding: "5px", }} htmlFor="selectElement">Type de lien :</label>
                    <select value={selectedOption} onChange={handleSelectChange} style={{ padding: "5px", width: '50%', "margin-left": "20px" }}>
                        {selectOptions && selectOptions.map((option, indx) => {
                            return (<option key={indx} value={option.id}>{option.name["fr-FR"]}</option>)
                        })}
                    </select>
                </div>
                <div style={{ flex: "1", font: "400 14px \"Open Sans\",sans-serif", marginTop: "25px", marginRight:10 }}>
                    {resultMessage &&
                        resultMessage.split("\n").filter(line => line && line.length > 0).map((line, index) => {
                            let colorApply = "black"
                            if(line.includes("NOK")) {
                                colorApply ="#FF8787"
                            } else if (line.includes("OK")) {
                                colorApply ="#38D9A9"
                            }
                            
                            return (
                                <React.Fragment key={index}>
                                    <p style={{ backgroundColor:"white", padding:5, margin:0, color:colorApply }}>{line}
                                        <br />
                                    </p>
                                </React.Fragment>)
                            
                            
                        })

                    }
                </div>
            </div>

            <div style={{ flex: '2', "margin-top": "15px", "flex-direction": "column" }}>
                <div style={{ display: 'flex' }}>
                    <label style={{ "font-size": "14px", "font-family": "Poppins, Arial, sans-serif", padding: "5px" }} htmlFor="inputElement">Recherche de {quableAppType === "mass_link_media" ? "Media" : "Documents"} :</label>
                    <input
                        placeholder='Recherche'
                        type="text"
                        value={inputValue}
                        onChange={(event) => {
                            setInputValue(event.target.value);
                        }}
                        style={{ padding: "5px", width: '40%', "margin-left": "20px" }}
                    />
                    {allItems && inputValue && (inputValue.length >= 3 && allItems.length === 0 && loader === false) && (<div style={{ "font-size": "14px", "font-family": "Poppins, Arial, sans-serif", padding: "5px", "margin-left": "10px" }}>Aucun résultat n'a été trouvé.</div>)}
                </div>
                <div style={{ backgroundColor: '#ffffff', marginTop: '20px', "overflowY": "scroll", height: "300px" }}>
                    {loader ? (<BeatLoader color="#556ee6" />) :
                        (<ul style={{marginTop:0, marginBlockEnd:0,paddingInlineStart:0}}>
                            {allItems.map((item) => {
                                //console.log("my item", item)
                                return (
                                    <li style={{ color: "black", display: "flex", alignItems: "center",padding:5, minHeight: "50px", cursor: "pointer", borderStyle:"solid ", borderWidth:"3px", borderColor:"rgb(248,248,248)" }} key={item.id} onClick={() => handleSelectionClick(item)}>
                                        &#x2794;
                                        {(item.medias && item.medias.split('/').pop().split('.').pop() === ("jpg" || "png")) && <img style={{ "margin-left": '10px' }} src={item.medias} alt="thumbnail" width={80} height={80} />}
                                        <div style={{ color: "black", marginLeft: "10px", font: "400 14px \"Open Sans\",sans-serif" }}>{item.label}</div>
                                    </li>
                                )
                            })}
                        </ul>)}
                </div>
                <div>
                    <p style={{font: "400 14px \"Open Sans\",sans-serif"}}>
                    {quableAppType === "mass_link_media" ? "Media" : "Documents"} sélectionnés :
                    </p>
                </div>
                <div style={{ backgroundColor: '#ffffff', marginTop: '20px', "overflowY": "scroll", height: "300px" }}>
                    <ul style={{marginTop:0, marginBlockEnd:0,paddingInlineStart:0}}>
                        {selectedItems.map((item) => (
                            <li style={{ color: "blach", display: "flex", alignItems: "center",padding:5, minHeight: "50px", cursor: "pointer", borderStyle:"solid ", borderWidth:"3px", borderColor:"rgb(248,248,248)" }} key={item.id} >
                                <div onClick={() => handleSelectionClick(item)}>&#x2716;</div>
                                {(item.medias && item.medias.split('/').pop().split('.').pop() === ("jpg" || "png")) && <img style={{ marginLeft: "10px" }} src={item.medias} alt="thumbnail" width={80} height={80} onClick={() => handleSelectionClick(item)} />}
                                <div style={{ color: "black", marginLeft: "10px", font: "400 14px \"Open Sans\",sans-serif" }} onClick={() => handleSelectionClick(item)} >{item.label}</div>
                                {listAttr.map((attr) => {
                                
                                if ( attr.type === "simple_select") {
                                    return (
                                        <select name={attr.name['fr-FR']} style={{ padding: "5px", width: '50%', "margin-left": "20px" }} onChange={(event) => {
                                            addContent(event.target.value,attr, item);
                                        }} >
                                            {
                                                attr.options.map((option) => {
                                                    console.log(option)
                                                    return (
                                                        <option value={option.id} >{option.name}</option>
                                                    )
                                                }) 
                                            }

                                        </select>
                                    )
                                }else if (attr.type ==="multi_select"){
                                    return (
                                        <select name={attr.name['fr-FR']} style={{ padding: "5px", width: '50%', "margin-left": "20px" }} multiple onChange={(event) => {
                                            addContent(event.target.options,attr, item);
                                        }}>
                                            {
                                                attr.options.map((option) => {
                                                    console.log(option)
                                                    return (
                                                        <option value={option.id} >{option.name}</option>
                                                    )
                                                }) 
                                            }

                                        </select>
                                    )
                                } else {
                                    return (
                                        <input
                                        placeholder={attr.name['fr-FR']}
                                        type={attr.type === "integer" || attr.type === "decimal" ? "number" : "text"}
                                        onChange={(event) => {
                                            addContent(event.target.value,attr, item);
                                        }}
                                        style={{ padding: "5px", width: '40%', "margin-left": "20px" }}
                                    />)
                                    
                                }
                            })}

                            </li>
                        ))}
                    </ul>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between", padding: "5px" }}>
                    <div style={{visibility: quableAppType === "mass_link_media" ? "hidden": "visible"}}>  
                        <input type='checkbox' id="forcingEmpty" name="forcingEmpty" 
                        onChange={(event)=> {
                            setForceEmpty(!forceEmpty)

                        }}
                        checked={forceEmpty} 
                        style={{margin:0, height:20, width:20}} />
                        <label for="forcingEmpty" style={{ color: "black", marginLeft: "10px", font: "400 14px \"Open Sans\",sans-serif", position:"absolute", marginTop:3 }}>Si non renseignés, vider les attributs de liaison</label>
                    </div>
                    
                    <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                        {resultMessage === "Association en cours"?<BeatLoader color="#556ee6" />:null}
                        <button style={{ cursor: "pointer", minWidth: "75px", textTransform: "uppercase", padding: "9px", fontWeight: "bold", minHeight: "30px", background: "#f80", border: "0px", margin: "0px", borderRadius: "3px", color: "#FFF" }} onClick={async () => await handleSaveClick()}>Enregistrer</button>
                    </div>
                    
                    
                    
                </div>
            </div>
        </div >
    );
};

export default MyMassiveLinks;